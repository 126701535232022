import LargeContainer from '../../UI/Container/LargeContainer'
import oilIcon from '../../../assets/image/services/oil/icon.svg'
import waterIcon from '../../../assets/image/services/water/icon.svg'
import solarIcon from '../../../assets/image/services/solar/icon.svg'
import airIcon from '../../../assets/image/services/air/icon.svg'

import solar from '../../../assets/image/index/services/solar.jpg'
import air from '../../../assets/image/index/services/air.jpg'
import oil from '../../../assets/image/index/services/oil.jpg'
import water from '../../../assets/image/index/services/water.jpg'

import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { getUUID } from '../../../utility'
import Maintenance from './Maintenance'
import Button from '../../UI/Button/Button'

const Services = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const icons = [solarIcon, waterIcon, airIcon, oilIcon]
  const images = [solar, water, air, oil]
  const colors = ['bg-green', 'bg-blue', 'bg-lightBlue', 'bg-orange']

  return (
    <div className="mt-20">
      <LargeContainer>
        <div className="flex flex-col gap-8">
          {t.index.services.items.map((s: any, i: number) => (
            <div key={getUUID()} className={`flex flex-col-reverse ${i % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'}`}>
              <div className={`w-full lg:w-6/12 p-10 flex flex-col justify-between gap-10 ${colors[i]}`}>
                <div>
                  <div className="font-bold text-2xl ">{s.headline}</div>
                  <div className="text-xl font-thin leading-8 ">{s.subline}</div>
                  <div className="flex gap-3 flex-col mt-3">
                    {s.text.map((text: string, index: number) => (
                      <div key={index} className=" text-sm font-light">
                        {text}
                      </div>
                    ))}
                  </div>
                </div>
                <Button href="/kontakt" text={t.index.services.cta} className="text-sm mx-auto px-16" />
              </div>
              <div className="w-full lg:w-8/12 relative">
                <img
                  src={icons[i]}
                  alt=""
                  className={`absolute h-20 lg:h-28 bottom-3 lg:top-1/2 lg:-translate-y-1/2 left-1/2 -translate-x-1/2 lg:translate-x-0 ${
                    i % 2 === 0 ? 'lg:left-5' : 'lg:right-5 lg:left-auto'
                  }`}
                />
                <img src={images[i]} alt="" className="w-full aspect-video lg:h-full object-cover" />
              </div>
            </div>
          ))}
          <Maintenance />
        </div>
      </LargeContainer>
    </div>
  )
}

export default Services

import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { Image } from '../ServiceTemplate'
import { useEffect, useState } from 'react'
import MediumContainer from '../../../UI/Container/MediumContainer'
import Button from '../../../UI/Button/Button'

type Props = {
  id?: string
  color: string
  headline: string
  text: string
  image: Image
  reverse?: boolean
  end?: boolean
}

const Section: React.FC<Props> = ({ id, color, headline, text, image, reverse, end }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [bg, setBG] = useState(null)
  const [icons, setIcons] = useState(null)

  useEffect(() => {
    if (typeof image === 'string') {
      setBG(image)
    } else {
      setBG(image.bg)
      setIcons(image.icons)
    }
  }, [image])

  if (end) {
    return (
      <div className={`w-full`}>
        <img src={bg} alt="" className="aspect-video lg:aspect-auto w-full object-cover" />
        <div className={`bg-${color} p-10`}>
          <MediumContainer>
            <div className="text-lg lg:text-xl lg:leading-8 font-bold text-center">{headline}</div>
            <div className="font-light mt-5 text-sm text-center">{text}</div>
            <Button href="/kontakt" text={t.generic.cta.request} className="mx-auto mt-10" />
          </MediumContainer>
        </div>
      </div>
    )
  }

  return (
    <div id={id} className="bg-center bg-cover scroll-mt-20" style={{ backgroundImage: `url(${bg})` }}>
      <div className={`lg:w-2/5 xl:w-1/3 ${reverse ? 'ml-auto' : ''}`}>
        <div className="block aspect-video w-full lg:hidden">
          <img src={bg} alt={headline} className="w-full h-full object-cover" />
        </div>
        {Array.isArray(icons) && (
          <div
            className={`bg-${color} bg-5 lg:pb-10 pt-10 lg:pt-0 lg:bg-transparent flex flex-row flex-wrap items-center justify-around px-10 lg:mt-48`}
          >
            {icons.map((icon: string, i: number) => (
              <img src={icon} alt="" className="h-16 w-auto" key={i} />
            ))}
          </div>
        )}
        <div className={`bg-${color} p-5 lg:p-10 ${!Array.isArray(icons) && 'lg:mb-28'}`}>
          <div className="text-lg lg:text-xl leading-8 font-bold">{headline}</div>
          <div className="font-light mt-5 text-sm">{text}</div>
          <Button href="/kontakt" text={t.generic.cta.request} className="mx-auto mt-10" />
        </div>
      </div>
    </div>
  )
}

export default Section

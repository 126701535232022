import { useSelector } from 'react-redux'
import bg from '../../../assets/image/index/services/maintenance/bg.jpg'
import bgMobile from '../../../assets/image/index/services/maintenance/bg_mobile.jpg'
import emergency from '../../../assets/image/index/services/maintenance/emergency.png'
import professional from '../../../assets/image/index/services/maintenance/professional.png'
import speed from '../../../assets/image/index/services/maintenance/speed.png'
import props from '../../../redux/props'
import Button from '../../UI/Button/Button'
import styled from 'styled-components'

const Maintenance = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const icons = [emergency, professional, speed]

  const Content = styled.div`
    background-image: url(${bg});
    background-size: cover;
    background-position: left center;
    @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      background-image: url(${bgMobile});
      background-position: center top;
    }
  `

  return (
    <Content className="bg-center bg-cover p-2 lg:p-5">
      <div className="bg-background rounded-xl p-5 lg:p-10 mt-[30vh] lg:mt-[20vh] lg:w-6/12 ml-auto">
        <div className="text-2xl leading-8 font-bold">{t.index.services.maintenance.headline}</div>
        <div className="text-xl font-thin">{t.index.services.maintenance.subline}</div>
        <div className="font-light mt-5 text-sm">{t.index.services.maintenance.text}</div>
        <div className="flex flex-row flex-wrap items-center gap-3 lg:gap-10 mt-5">
          {icons.map((icon: string, i: number) => (
            <img src={icon} alt="" className="h-6 lg:h-8 w-auto" key={i} />
          ))}
        </div>
        <Button href="/kontakt" dark text={t.index.services.maintenance.cta} className="mx-auto mt-10" />
      </div>
    </Content>
  )
}

export default Maintenance

import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import styled from 'styled-components'

const Reasons = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const Item = styled.div`
    width: calc(100% / 3 - (10px * 2 / 3));
    aspect-ratio: 1/1;
    @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      width: calc(100% / 2 - (10px / 2));
    }
    @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
      width: calc(100% - 10px);
    }
  `

  return (
    <div className="my-16">
      <div className="text-lg lg:text-xl text-gray font-bold text-center">{t.career.reasons.headline}</div>
      <div className="flex flex-col sm:flex-row flex-wrap gap-[10px] mt-10">
        {t.career.reasons.items.map((reason: string, index: number) => (
          <Item key={index} className="flex flex-col justify-between bg-green p-3 lg:gap-3">
            <div className="font-thin text-black !text-[250px] leading-[250px] lg:!text-[150px] lg:leading-[150px]">{index + 1}</div>
            <div className="font-bold text-black">{reason}</div>
          </Item>
        ))}
      </div>
    </div>
  )
}
export default Reasons

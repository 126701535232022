import { lambdaURLContactAndApplication } from '../../utility'

export enum Type {
  APPLICATION = 'application',
  CONTACT = 'contact',
}

type Response = { statusCode: number }

const useSendForm = () => {
  const sendForm = async (type: Type, data: any) => {
    const rawResponse = await fetch(lambdaURLContactAndApplication, {
      method: 'POST',
      body: JSON.stringify({ type, data }),
    })

    if (!rawResponse) return false
    const response = (await rawResponse.json()) as Response

    if (response.statusCode === 200) return true
  }

  return sendForm
}

export default useSendForm

import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import bg from '../../../assets/image/career/benefits/bg.jpg'
import oil from '../../../assets/image/services/oil/icon.svg'
import water from '../../../assets/image/services/water/icon.svg'
import solar from '../../../assets/image/services/solar/icon.svg'
import air from '../../../assets/image/services/air/icon.svg'

const Benefits = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const row1Images = [oil, water]
  const row2Images = [solar, air]

  return (
    <div className="my-16">
      <div className="font-bold text-lg lg:text-xl text-gray text-center">{t.career.benefits.headline}</div>
      <div className="bg-cover bg-center p-10 mt-10" style={{ backgroundImage: `url(${bg})` }}>
        <div className="font-bold text-lg lg:text-xl text-white text-center">{t.career.benefits.subtitle}</div>
        <div className="flex flex-col md:flex-row gap-3 lg:w-3/5 mx-auto mt-5 md:mt-10">
          {t.career.benefits.row1.map((text: string, i: number) => (
            <div className="flex flex-1 flex-row gap-3 items-center" key={i}>
              <img src={row1Images[i]} alt={text} className="h-16 md:h-20 aspect-square" />
              <div className="text-white whitespace-pre-wrap">{text}</div>
            </div>
          ))}
        </div>
        <div className="flex flex-col md:flex-row gap-3 lg:w-3/5 mx-auto mt-5 md:mt-10">
          {t.career.benefits.row2.map((text: string, i: number) => (
            <div className="flex flex-1 flex-row gap-3 items-center" key={i}>
              <img src={row2Images[i]} alt={text} className="h-16 md:h-20 aspect-square" />
              <div className="text-white whitespace-pre-wrap">{text}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default Benefits

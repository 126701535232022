import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, QuestionMarkCircleIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'
import Button from '../Button/Button'
import Form from '../Form/Form'
import props from '../../../redux/props'

export enum TYPE {
  WARNING = 'warning',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
}

type Props = {
  type?: TYPE
  show: boolean
  onClose: () => void
  title?: string
  children?: React.ReactNode
  text?: string
  confirmButtonText?: string
  cancelButtonText?: string
  onConfirm?: () => void
  closeIcon?: boolean
  large?: boolean
  noForm?: boolean
  noClose?: boolean
  glass?: boolean
}

const Modal: React.FC<Props> = ({
  type,
  show,
  onClose,
  title,
  children,
  text,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  closeIcon = false,
  large = false,
  noForm = false,
  noClose = false,
  glass = false,
}) => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  const getIcon = () => {
    if (!type) return null
    switch (type) {
      case TYPE.WARNING:
        return (
          <div className="sm:mr-4 mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
        )
      case TYPE.INFO:
        return (
          <div className="sm:mr-4 mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
            <QuestionMarkCircleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
          </div>
        )
      case TYPE.SUCCESS:
        return (
          <div className="sm:mr-4 mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
            <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
        )
      default:
        return null
    }
  }

  const Core = (
    <div>
      <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        {closeIcon && !noClose && <XCircleIcon className="absolute top-1 right-1 h-7 text-secondary cursor-pointer" onClick={onClose} />}
        <div className={!!type ? 'sm:flex sm:items-start' : ''}>
          {getIcon()}
          <div className="text-center sm:mt-0 sm:text-left">
            {!!title && (
              <Dialog.Title as="h3" className="mb-2 text-lg font-semibold leading-6 text-gray-900">
                {title}
              </Dialog.Title>
            )}
            <div>
              {!!text && <p className="text-sm text-gray-500">{text}</p>}
              {!!children && children}
            </div>
          </div>
        </div>
      </div>
      {(!noClose || !!onConfirm) && (
        <div className="flex justify-between sm:justify-end gap-3 bg-gray-50 px-4 py-3 sm:px-6">
          {!closeIcon && !noClose && <Button dark inverted onClick={onClose} text={cancelButtonText || translation.generic.close} />}
          {!!onConfirm && (
            <Button
              dark
              type={noForm ? 'button' : 'submit'}
              onClick={noForm ? onConfirm : null}
              text={confirmButtonText || translation.generic.confirm}
            />
          )}
        </div>
      )}
    </div>
  )

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-11 box-border" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-gray-secondary bg-opacity-50">
          <div className="flex min-h-full items-center justify-center p-3 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-xl ${
                  glass ? 'bg-black bg-opacity-20 backdrop-blur-2xl border border-white' : 'bg-white'
                } text-left shadow-xl w-full transition-all sm:my-8 ${large ? `sm:max-w-screen-lg` : 'sm:max-w-lg'}`}
              >
                {noForm && <div>{Core}</div>}
                {!noForm && <Form onSubmit={onConfirm}>{Core}</Form>}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal

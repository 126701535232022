import styled from 'styled-components'
import bg from '../../../assets/image/index/hero/bg.jpg'
import bgMobile from '../../../assets/image/index/hero/bg_mobile.jpg'
import goDown from '../../../assets/image/go-down.svg'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import oil from '../../../assets/image/services/oil/icon.svg'
import water from '../../../assets/image/services/water/icon.svg'
import solar from '../../../assets/image/services/solar/icon.svg'
import air from '../../../assets/image/services/air/icon.svg'
import Button from '../../UI/Button/Button'
import Call from '../../UI/Call/Call'

const Content = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: left center;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    background-image: url(${bgMobile});
    background-position: center 25%;
  }
`

const Hero: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const services = [
    {
      name: t.index.hero.services.solar.name,
      icon: solar,
      link: '/waermepumpen-regenerative-energien',
    },
    {
      name: t.index.hero.services.water.name,
      icon: water,
      link: '/sanitartechnik-badsanierung',
    },
    {
      name: t.index.hero.services.air.name,
      icon: air,
      link: '/luftungsanlagen',
    },
    {
      name: t.index.hero.services.oil.name,
      icon: oil,
      link: '/ol-und-gasheizungen',
    },
  ]

  return (
    <Content className="min-h-screen py-20 md:py-0 md:h-screen relative flex items-center">
      <div className="w-full px-3 lg:ml-auto lg:w-2/3 xl:w-[850px] lg:px-0 h-full flex items-center">
        <div className="flex gap-12 flex-col md:w-11/12">
          <div>
            <div className="font-bold text-2xl md:text-3xl text-white">{t.index.hero.headline}</div>
            <div className="text-xl md:text-2xl leading-9 text-white">{t.index.hero.subline}</div>
          </div>
          <div className="flex gap-3 flex-col">
            {t.index.hero.text.map((text: string, index: number) => (
              <div key={index} className="text-white text-sm">
                {text}
              </div>
            ))}
          </div>
          <div className="flex flex-row flex-wrap gap-y-3 sm:flex-nowrap md:gap-3 justify-between">
            {services.map(({ name, icon, link }, index) => (
              <a href={link} className="w-1/4 flex flex-row gap-3 items-center justify-center" key={index}>
                <img src={icon} className="h-12 md:h-14" alt="" />
                <div className="hidden md:block">
                  {name.map((t: string, i: number) => (
                    <div className="text-xs text-white" key={i}>
                      {t}
                    </div>
                  ))}
                </div>
              </a>
            ))}
          </div>
          <div className="flex flex-col gap-3 items-center md:flex-row md:gap-5">
            <Button href="/kontakt" text={t.generic.cta.offer} />
            <Call inverted />
          </div>
        </div>
      </div>
      <img src={goDown} alt="" className="hidden md:block left-1/2 -translate-x-1/2 absolute bottom-10 z-1 h-16" />
    </Content>
  )
}

export default Hero

import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import MediumContainer from '../../UI/Container/MediumContainer'
import LargeContainer from '../../UI/Container/LargeContainer'
import Hero from './Hero/Hero'
import Section from './Section/Section'
import Button from '../../UI/Button/Button'

export type Image = string | { bg: string; icons: string[] }

export type Icons = string[]
export type Images = Image[]

type Props = {
  translationKey: string
  color: string
  heroImage: string
  heroImageMobile: string
  icon: string
  sectionIcons: Icons
  sectionImages: Images
  endSectionImage?: string
}

const ServiceTemplate: React.FC<Props> = ({
  translationKey,
  color,
  heroImage,
  heroImageMobile,
  icon,
  sectionIcons,
  sectionImages,
  endSectionImage,
}) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div>
      <Hero bg={heroImage} bgMobile={heroImageMobile} icon={icon} translationKey={translationKey} />
      <div className="text-xl lg:text-2xl font-bold text-center mt-16 leading-8">{t[translationKey].intro.headline}</div>
      <div className="text-lg lg:text-xl text-center">{t[translationKey].intro.subline}</div>
      <MediumContainer>
        <div className="flex flex-row flex-wrap lg:flex-nowrap gap-y-3 lg:gap-10 justify-center mt-10">
          {t[translationKey].intro.sections.map((s: string, i: number) => (
            <a href={`#section-${i}`} className="flex flex-col gap-3 items-center w-1/2 md:w-1/3 lg:w-1/4" key={i}>
              <img src={sectionIcons[i]} alt="" className="w-24 lg:w-28 aspect-square" />
              <div className="text-center text-sm">{s}</div>
            </a>
          ))}
        </div>
      </MediumContainer>
      <LargeContainer>
        <div className="flex flex-col gap-8 mt-20">
          {t[translationKey].sections.map((s: any, i: number) => (
            <Section id={`section-${i}`} key={i} reverse={i % 2 === 1} color={color} headline={s.headline} text={s.text} image={sectionImages[i]} />
          ))}
          {endSectionImage && (
            <Section
              end
              color={color}
              headline={t[translationKey].endSection.headline}
              text={t[translationKey].endSection.text}
              image={endSectionImage}
            />
          )}
        </div>
      </LargeContainer>
      <MediumContainer>
        <div className="text-xl font-bold text-center mt-20 leading-8">{t[translationKey].outro.headline}</div>
        <div className="text-sm text-center mt-5 w-10/12 mx-auto">{t[translationKey].outro.text}</div>
        <Button href="/kontakt" text={t.generic.cta.offer} dark className="mx-auto mt-10" />
      </MediumContainer>
    </div>
  )
}

export default ServiceTemplate

interface IButton {
  text?: string | React.ReactNode
  type?: string
  href?: string
  onClick?: () => void
  inverted?: boolean
  className?: string
  disabled?: boolean
  dark?: boolean
}

enum Type {
  DEFAULT = 'button',
  SUBMIT = 'submit',
}

const Button: React.FC<IButton> = ({
  text,
  onClick,
  href,
  inverted = false,
  type = Type.DEFAULT,
  className = '',
  disabled = false,
  dark = false,
}) => {
  const getClasses = () => {
    let classes = 'border py-1 px-3 sm:px-5 md:px-10 font-light rounded-full w-fit block transition-all'
    if (inverted) {
      if (dark) {
        classes += ' border-black bg-transparent text-black hover:bg-black hover:text-white'
      } else {
        classes += ' border-white bg-transparent text-white hover:bg-white hover:text-black'
      }
    } else {
      if (dark) {
        classes += ' border-black bg-black text-white hover:bg-transparent hover:text-black'
      } else {
        classes += ' border-white bg-white text-black hover:bg-transparent hover:text-white'
      }
    }
    return classes
  }

  if (!!href) {
    return (
      <a className={`${getClasses()} ${className}`} href={href}>
        {text}
      </a>
    )
  }
  return (
    <button
      className={`${getClasses()} ${className}`}
      type={type === Type.SUBMIT ? Type.SUBMIT : Type.DEFAULT}
      onClick={type !== Type.SUBMIT ? onClick : null}
      disabled={disabled}
    >
      {text}
    </button>
  )
}

export default Button

import React, { useState } from 'react'
import ReactDOM from 'react-dom'

type Props = {
  message: string
  type: Type
}

export enum Type {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

const Toast: React.FC<Props> = ({ message, type }) => {
  const [isVisible, setIsVisible] = useState(true)

  if (!isVisible) return null
  return (
    <div className="fixed z-10 left-0 top-0 lg:top-4 w-full flex justify-center">
      <div
        className={`dfb-Alert ${type !== Type.ERROR ? 'dfb-Alert--success' : 'dfb-Alert--error'} ${isVisible && 'is-visible'}`}
        tabIndex={-1}
        role="alert"
      >
        <div className="dfb-Alert-message">{message}</div>
        <button type="button" className="dfb-Alert-closeButton" onClick={() => setIsVisible(false)} data-dismiss="alert">
          <svg className="dfb-Symbol dfb-Symbol--dfbIconClose" viewBox="0 0 24 24">
            <use xlinkHref="#dfb-icon-close" href="#dfb-icon-close"></use>
          </svg>
        </button>
      </div>
    </div>
  )
}

const useToast = () => {
  const trigger = (message: string, type: Type = Type.ERROR) => {
    const toast = document.createElement('div')
    document.body.appendChild(toast)
    ReactDOM.render(<Toast message={message} type={type} />, toast)

    if (type !== Type.ERROR) {
      setTimeout(() => {
        ReactDOM.unmountComponentAtNode(toast)
        document.body.removeChild(toast)
      }, 5000)
    }
  }

  return trigger
}

export default useToast

import ServiceTemplate, { Icons, Images } from './ServiceTemplate/ServiceTemplate'
import icon from '../../assets/image/services/oil/icon.svg'
import hero from '../../assets/image/services/oil/hero.jpg'
import heroMobile from '../../assets/image/services/oil/hero_mobile.jpeg'

import consultancyIcon from '../../assets/image/services/oil/icon/consultancy.svg'
import solutionIcon from '../../assets/image/services/oil/icon/solution.svg'
import modernizationIcon from '../../assets/image/services/oil/icon/modernization.svg'
import planningIcon from '../../assets/image/services/oil/icon/planning.svg'

import consultancy from '../../assets/image/services/oil/section/consultancy.jpg'
import planning from '../../assets/image/services/oil/section/planning.jpg'
import solution from '../../assets/image/services/oil/section/solution.jpg'
import modernization from '../../assets/image/services/oil/section/modernization.jpg'

import moduleModernization from '../../assets/image/services/oil/section/modernization-icons/module.svg'
import houseModernization from '../../assets/image/services/oil/section/modernization-icons/house.svg'
import temperaturModernization from '../../assets/image/services/oil/section/modernization-icons/temperatur.svg'
import plugModernization from '../../assets/image/services/oil/section/modernization-icons/plug.svg'

import Layout from '../UI/Layout/Layout'

const Oil = () => {
  const icons: Icons = [consultancyIcon, planningIcon, solutionIcon, modernizationIcon]
  const images: Images = [
    consultancy,
    planning,
    solution,
    {
      bg: modernization,
      icons: [moduleModernization, houseModernization, temperaturModernization, plugModernization],
    },
  ]

  return (
    <Layout authLevelRequired={false}>
      <ServiceTemplate
        translationKey={'oil'}
        color={'orange'}
        heroImage={hero}
        heroImageMobile={heroMobile}
        icon={icon}
        sectionIcons={icons}
        sectionImages={images}
      />
    </Layout>
  )
}

export default Oil

import { backendDomain } from './utility'
import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios'

export enum METHOD {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export type URISuffix = String
export type Body = Object & { [key: string]: any }
export type Files = Array<File>
export type ProgressCallback = Function
export type FileDownloadMimeType = String
export type Headers = Object & { [key: string]: any }
export type MergeObject = Object & { [key: string]: any }
export type RequestConfig = AxiosRequestConfig<Object & { [key: string]: any }>
export type ProgressObject = AxiosProgressEvent
export type PresignedURL = string

const mergeRequestHeaders = (headers?: Headers, mergeObject?: MergeObject): Headers => {
  if (!headers) return mergeObject as Headers
  return { ...headers, ...mergeObject } as Headers
}

const prepRequestForDownload = (request: RequestConfig, fileDownloadMimeType?: FileDownloadMimeType) => {
  if (!fileDownloadMimeType) return request
  request.responseType = 'blob'
  request.headers = mergeRequestHeaders(request.headers, {
    Accept: fileDownloadMimeType,
  })
  return request
}

const prepRequestForUpload = (request: RequestConfig, body: Body, filesForUpload: Files = [], uploadProgressCallback?: ProgressCallback) => {
  if (!filesForUpload) return request

  request.method = METHOD.POST

  const formData = new FormData()
  if (Array.isArray(filesForUpload)) {
    filesForUpload.forEach((file) => formData.append('files', file))
  }
  if (typeof body === 'object') {
    Object.keys(body).forEach((key) => formData.append(key, body[key]))
  }

  if (typeof body === 'object' && body.token) {
    request.url = `${request.url}?token=${body.token}`
  }

  request.data = formData
  request.headers = mergeRequestHeaders(request.headers, {
    'Content-Type': 'multipart/form-data',
  })

  if (typeof uploadProgressCallback === 'function') {
    request.onUploadProgress = (e: ProgressObject) => {
      const progress = Math.round((e.loaded / (e.total || 0)) * 100)
      uploadProgressCallback(progress)
    }
  }
  return request
}

const fetch = async (
  method: METHOD,
  uriSuffix: URISuffix,
  body: Body = {},
  filesForUpload?: Files,
  uploadProgressCallback?: ProgressCallback,
  fileDownloadMimeType?: FileDownloadMimeType,
) => {
  try {
    if (!uriSuffix) return false
    let request = {
      method,
      url: `${backendDomain}${uriSuffix}`,
      [method === METHOD.GET ? 'params' : 'data']: body,
    } as RequestConfig

    request = prepRequestForDownload(request, fileDownloadMimeType)
    request = prepRequestForUpload(request, body, filesForUpload, uploadProgressCallback)

    const result = await axios(request)
    return result.data
  } catch (err) {
    return false
  }
}

export const s3Upload = async (presignedURL: PresignedURL, file: File, progressCallback: ProgressCallback) => {
  const uploadResult = await axios.put(presignedURL, file, {
    headers: { 'Content-Type': file.type },
    onUploadProgress: (e) => {
      if (typeof progressCallback !== 'function') return
      const progress = Math.round((e.loaded / (e.total || 0)) * 100)
      progressCallback(progress)
    },
  })
  return uploadResult.status === 200
}

export const s3Download = async (presignedURL: PresignedURL) => {
  let link = document.createElement('a')
  link.href = presignedURL
  link.click()
}

export default fetch

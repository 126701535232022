import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import GoogleMap from './GoogleMap/GoogleMap'

const Map = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const mapsOptions = { zoom: 15, center: { lat: 48.5099311, lng: 10.9666447 } }
  const markers = [t.contact.googleMap.location]
  return (
      <div className="w-full h-full mt-20 scroll-mt-16" id="karte">
        <div className="font-bold text-lg lg:text-xl text-center text-gray">{t.contact.googleMap.title}</div>
        <div className="w-full aspect-video mt-5">
        <GoogleMap options={mapsOptions} markers={markers} />
        </div>
      </div>
  )
}

export default Map

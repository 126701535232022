import styled from 'styled-components'
import bg from '../../../assets/image/contact/hero.jpg'
import goDown from '../../../assets/image/go-down.svg'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Button from '../../UI/Button/Button'
import Call from '../../UI/Call/Call'

const Content = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: bottom center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    background-position: 25% center;
  }
`

const Hero: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <Content className="min-h-screen py-20 md:py-0 md:h-screen relative flex items-end lg:items-center">
      <div className="w-full px-3 lg:ml-auto lg:w-[750px] lg:px-0 h-full flex items-center">
        <div className="flex gap-8 flex-col md:w-11/12">
          <div>
            <div className="font-bold text-2xl md:text-3xl leading-10 text-white">{t.contact.hero.headline}</div>
            <div className="text-xl md:text-2xl leading-9 mt-2 text-white">{t.contact.hero.subline}</div>
          </div>
          <div className="flex flex-col gap-3 items-center md:flex-row md:gap-5">
            <Button href="/kontakt#formular" text={t.contact.hero.cta} />
            <Call inverted />
          </div>
        </div>
      </div>
      <img src={goDown} alt="" className="hidden md:block left-1/2 -translate-x-1/2 absolute bottom-10 z-1 h-16" />
    </Content>
  )
}

export default Hero

import { getUUID } from '../../../utility'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'

type Props = {
  className?: string
  color?: string
  onChange?: (value: any) => void
  value?: any
  items?: { label: string; value: any }[]
  required?: boolean
  emptySelection?: string
  showEmptySelection?: boolean
}

const Dropdown: React.FC<Props> = ({
  className = '',
  color,
  onChange,
  value,
  items = [],
  required = false,
  emptySelection,
  showEmptySelection = true,
}) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <select
      required={required}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={`border-none placeholder-gray font-bold w-full py-3 px-10 bg-lightGray text-gray rounded-lg active:outline-none focus:outline-none text-sm text-blue-primary ${className}`}
    >
      {showEmptySelection && (
        <option key={getUUID()} value={''} color={color}>
          {emptySelection || translation.UI.dropdown.emptySelection}
        </option>
      )}
      {items.map((item) => (
        <option key={getUUID()} value={item.value} color={color}>
          {item.label}
        </option>
      ))}
    </select>
  )
}

export default Dropdown

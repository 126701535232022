import Layout from '../UI/Layout/Layout'
import Hero from './Hero/Hero'
import Services from './Services/Services'

const Index = () => {
  return (
    <Layout authLevelRequired={false}>
      <Hero />
      <Services />
    </Layout>
  )
}

export default Index

import { getDateInputFormat } from '../../../utility'

export interface BaseInput extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  id?: string
  type?: string
  onChange: (value: any) => void
  label?: string
  placeholder?: string
  className?: string
  contentClassName?: string
  required?: boolean
  Icon?: any
  value: any
  autoComplete?: string
  prefixText?: string
  suffixText?: string
  returnRawChangeEvent?: boolean
  disabled?: boolean
  hiddenDisabled?: boolean
  textAlign?: string
  useRef?: any
}

const Input: React.FC<BaseInput> = ({
  id,
  type = 'text',
  value,
  label,
  placeholder,
  onChange,
  required = false,
  className = '',
  autoComplete = 'on',
  returnRawChangeEvent = false,
  disabled = false,
  hiddenDisabled = false,
  textAlign = null,
  useRef,
}) => {
  const prepValue = () => {
    if (!value) return ''
    if (type === 'date') {
      if (typeof value === 'object') {
        return getDateInputFormat(value)
      } else if (value.includes('T')) {
        return getDateInputFormat(new Date(value))
      }
      return value
    } else {
      return value
    }
  }

  return (
    <input
      id={id}
      ref={useRef}
      disabled={disabled || hiddenDisabled}
      required={required}
      type={type}
      value={prepValue()}
      onChange={(e) => onChange(returnRawChangeEvent ? e : e.target.value)}
      placeholder={placeholder || label}
      className={`border-none placeholder-gray font-bold w-full py-3 px-10 bg-lightGray text-gray rounded-lg focus:outline-none focus:shadow-outline ${className} ${
        !!textAlign ? ` text-${textAlign}` : ''
      }`}
      autoComplete={autoComplete}
    />
  )
}

export default Input

import { useSelector } from 'react-redux'
import Input from '../../UI/Input/Input'
import props from '../../../redux/props'
import { useState } from 'react'
import UIForm from '../../UI/Form/Form'
import callIcon from '../../../assets/image/call/call.svg'
import SmallContainer from '../../UI/Container/SmallContainer'
import Dropdown from '../../UI/Dropdown/Dropdown'
import Textarea from '../../UI/Textarea/Textarea'
import Button from '../../UI/Button/Button'
import useSendForm, { Type } from '../../hooks/useSendForm'
import Loading from '../../UI/Loading/Loading'

const timeslots = {
  7: '7:00 - 8:00',
  8: '8:00 - 9:00',
  9: '9:00 - 10:00',
  10: '10:00 - 11:00',
  11: '11:00 - 12:00',
  12: '12:00 - 13:00',
  13: '13:00 - 14:00',
  14: '14:00 - 15:00',
  15: '15:00 - 16:00',
  beliebig: 'beliebig',
}

const topics = {
  newBuild: 'Neubau',
  heatPump: 'Wärmepumpe',
  water: 'Sanitärtechnik & Badsanierung',
  solar: 'Wärmepumpen & Regenerative Energien',
  air: 'Lüftungsanlagen',
  oil: 'Öl- und Gasheizungen',
  other: 'Sonstiges',
}

type Data = {
  name?: string
  mail?: string
  phone?: string
  timeslot?: string
  topic?: string
  message?: string
}

enum Status {
  DEFAULT = 'DEFAULT',
  SUBMITTING = 'SUBMITTING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

const Form = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [data, setData] = useState<Data>({})
  const sendForm = useSendForm()
  const [status, setStatus] = useState<Status>(Status.DEFAULT)

  const set = (key: string) => (value: string) => setData({ ...data, [key]: value })

  const submit = async () => {
    setStatus(Status.SUBMITTING)
    const status = await sendForm(Type.CONTACT, {
      ...data,
      timeslot: timeslots[data.timeslot as string],
      topic: topics[data.topic as string],
    })
    if (status) {
      setStatus(Status.SUCCESS)
    } else {
      setStatus(Status.ERROR)
    }
  }

  return (
    <div className="my-16 scroll-mt-16" id="formular">
      <div className="flex flex-col md:flex-row items-center justify-center lg:gap-3">
        <div className="font-bold text-lg lg:text-xl text-gray">{t.contact.form.callPrefix}</div>
        <a className="font-bold text-lg lg:text-xl flex flex-row items-center gap-2 text-gray" href={`tel:${t.generic.company.phone}`}>
          <img src={callIcon} alt="call" className="w-10 aspect-square" />
          {t.generic.company.phone}
        </a>
      </div>
      <div className="border-t w-36 mx-auto mt-3 lg:mt-5 mb-3"></div>
      <div className="font-bold text-lg lg:text-xl text-center text-gray">{t.contact.form.orForm}</div>
      <SmallContainer>
        <UIForm onSubmit={submit}>
          <div className="flex flex-col gap-3 mt-10 items-center">
            <Input value={data.name} onChange={set('name')} placeholder={t.generic.name} required />
            <Input value={data.mail} onChange={set('mail')} placeholder={t.generic.mail} type="mail" required />
            <Input value={data.phone} onChange={set('phone')} placeholder={t.generic.phone} required />
            <div className="text-gray text-center">{t.contact.form.timeslotTitle}</div>
            <Dropdown
              items={Object.keys(timeslots).map((t) => ({ label: timeslots[t], value: t }))}
              value={data.timeslot}
              onChange={set('timeslot')}
              required
              className="w-full lg:w-2/5 min-w-52 mx-auto"
            />
            <div className="text-gray text-center">{t.contact.form.topicTitle}</div>
            <Dropdown items={Object.keys(topics).map((t) => ({ label: topics[t], value: t }))} value={data.topic} onChange={set('topic')} required />
            <Textarea rows={10} value={data.message} onChange={set('message')} placeholder={t.generic.message} required />
            {status === Status.DEFAULT && <Button type="submit" text={t.contact.form.cta} className="py-2 lg:py-4" dark />}
            {status === Status.SUBMITTING && (
              <div className="flex flex-col justify-center mt-5 gap-3">
                <Loading loading={false} />
                <div className="text-center font-bold text-gray">{t.contact.form.status.processing}</div>
              </div>
            )}
            {status === Status.SUCCESS && (
              <div className="flex flex-col justify-center mt-5 gap-3">
                <div className="text-center font-bold text-green">{t.contact.form.status.success}</div>
              </div>
            )}
            {status === Status.ERROR && (
              <div className="flex flex-col justify-center mt-5 gap-3">
                <div className="text-center font-bold text-orange">{t.contact.form.status.error}</div>
              </div>
            )}
          </div>
        </UIForm>
      </SmallContainer>
    </div>
  )
}

export default Form

import Container from '../UI/Container/Container'
import Layout from '../UI/Layout/Layout'
import Hero from './Hero/Hero'
import Benefits from './Benefits/Benefits'
import Reasons from './Reasons/Reasons'
import Positions from './Positions/Positions'
import MediumContainer from '../UI/Container/MediumContainer'

const Career = () => {
  return (
    <Layout authLevelRequired={false}>
      <Hero />
      <Container>
        <Benefits />
      </Container>
      <MediumContainer>
        <Reasons />
      </MediumContainer>
      <Positions />
    </Layout>
  )
}

export default Career

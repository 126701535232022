import Container from '../UI/Container/Container'
import Layout from '../UI/Layout/Layout'
import Form from './Form/Form'
import Hero from './Hero/Hero'
import Map from './Map/Map'

const Contact = () => {
  return (
    <Layout authLevelRequired={false}>
      <Hero />
      <Container>
        <Form />
        <Map />
      </Container>
    </Layout>
  )
}

export default Contact

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import './Global.Styled'
import Index from './components/Index/Index'
import Oil from './components/Service/Oil'
import Water from './components/Service/Water'
import Solar from './components/Service/Solar'
import Air from './components/Service/Air'
import Imprint from './components/Legal/Imprint'
import DataProtection from './components/Legal/DataProtection'
import Contact from './components/Contact/Contact'
import Career from './components/Career/Career'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            {/* home */}
            <Route index element={<Index />} />

            {/* service pages */}
            <Route path={'ol-und-gasheizungen'} element={<Oil />} />
            <Route path={'sanitartechnik-badsanierung'} element={<Water />} />
            <Route path={'waermepumpen-regenerative-energien'} element={<Solar />} />
            <Route path={'luftungsanlagen'} element={<Air />} />

            {/* contact */}
            <Route path={'kontakt'} element={<Contact />} />

            {/* career */}
            <Route path={'karriere'} element={<Career />} />

            {/* legal */}
            <Route path={'impressum'} element={<Imprint />} />
            <Route path={'datenschutz'} element={<DataProtection />} />

            {/* fallback routing */}
            <Route path={'*'} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  )
}

export default App

import Header from './Header/Header'
import Footer from './Footer/Footer'
import Secure from './Secure/Secure'
import ScrollToTop from './ScrollToTop/ScrollToTop'
import { AuthLevelRequired } from '../../../types/Auth'

type Props = {
  children: React.ReactNode
  className?: string
  authLevelRequired?: AuthLevelRequired
}

const Layout: React.FC<Props> = ({ children, className, authLevelRequired = [] }) => {
  return (
    <div className={className}>
      <Secure authLevelRequired={authLevelRequired}>
        <ScrollToTop />
        <Header />
        <div className="relative bg-background min-h-screen pb-24">{children}</div>
        <Footer />
      </Secure>
    </div>
  )
}

export default Layout

import Toggle from './Toggel/Toggle'
import Items from './Items/Items'

type Props = {
  didScroll?: boolean
}

const Navigation: React.FC<Props> = ({ didScroll = false }) => {
  return (
    <div>
      <div className="block lg:hidden">
        <Toggle didScroll={didScroll} />
      </div>
      <div className="hidden lg:flex justify-around gap-8 items-center">
        <Items didScroll={didScroll} />
      </div>
    </div>
  )
}

export default Navigation

import ServiceTemplate, { Icons, Images } from './ServiceTemplate/ServiceTemplate'
import icon from '../../assets/image/services/water/icon.svg'
import hero from '../../assets/image/services/water/hero.jpg'
import heroMobile from '../../assets/image/services/water/hero_mobile.jpg'

import planningIcon from '../../assets/image/services/water/icon/planning.svg'
import consultancyIcon from '../../assets/image/services/water/icon/consultancy.svg'
import repairIcon from '../../assets/image/services/water/icon/repair.svg'
import implementationIcon from '../../assets/image/services/water/icon/implementation.svg'

import planning from '../../assets/image/services/water/section/planning.jpg'
import consultancy from '../../assets/image/services/water/section/consultancy.jpg'
import repair from '../../assets/image/services/water/section/repair.jpg'
import implementation from '../../assets/image/services/water/section/implementation.jpg'
import silence from '../../assets/image/services/water/section/silence.jpg'

import Layout from '../UI/Layout/Layout'

const Water = () => {
  const icons: Icons = [planningIcon, consultancyIcon, repairIcon, implementationIcon]
  const images: Images = [planning, consultancy, repair, implementation]

  return (
    <Layout authLevelRequired={false}>
      <ServiceTemplate
        translationKey={'water'}
        color={'blue'}
        heroImage={hero}
        heroImageMobile={heroMobile}
        icon={icon}
        sectionIcons={icons}
        sectionImages={images}
        endSectionImage={silence}
      />
    </Layout>
  )
}

export default Water

import { s3Upload } from '../../fetch'
import { lambdaURLPresignedUploadURL } from '../../utility'

type PresignedUploadResponse = {
  url: string
  key: string
}

export enum UPLOAD_TYPES {
  APPLICATION = 'APPLICATION',
}

export type OnCallbackFile = {
  url: string
  key: string
  name: string
  mimeType: string
}

type Upload = (type: UPLOAD_TYPES, file: File, progressCallback: (p: number) => void) => Promise<OnCallbackFile | false>
type Props = () => Upload

const useFileUpload: Props = () => {
  const upload: Upload = async (type, file, progressCallback) => {
    const response = await fetch(lambdaURLPresignedUploadURL, {
      method: 'POST',
      body: JSON.stringify({ type, meta: { type: file.type, name: file.name, size: file.size } }),
    })

    if (!response) return false
    const json = await response.json()

    if (!json || !json.body || json.statusCode !== 200) return false
    const { url, key } = JSON.parse(json.body) as PresignedUploadResponse

    const uploadResult = await s3Upload(url, file, progressCallback)
    if (!uploadResult) return false
    return { url, key, name: file.name, mimeType: file.type }
  }

  return upload
}

export default useFileUpload

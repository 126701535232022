import ServiceTemplate, { Icons, Images } from './ServiceTemplate/ServiceTemplate'
import icon from '../../assets/image/services/solar/icon.svg'
import hero from '../../assets/image/services/solar/hero.webp'
import heroMobile from '../../assets/image/services/solar/hero_mobile.webp'

import consultancyIcon from '../../assets/image/services/solar/icon/consultancy.svg'
import woodIcon from '../../assets/image/services/solar/icon/wood.svg'
import heatpumpIcon from '../../assets/image/services/solar/icon/heatpump.svg'
import solarIcon from '../../assets/image/services/solar/icon/solar.svg'

import consultancy from '../../assets/image/services/solar/section/consultancy.jpg'
import wood from '../../assets/image/services/solar/section/wood.jpg'
import heatpump from '../../assets/image/services/solar/section/heatpump.jpg'
import solar from '../../assets/image/services/solar/section/solar.jpg'

import houseSolar from '../../assets/image/services/solar/section/solar-icons/house.svg'
import moduleSolar from '../../assets/image/services/solar/section/solar-icons/module.svg'
import batterySolar from '../../assets/image/services/solar/section/solar-icons/battery.svg'
import greenSolar from '../../assets/image/services/solar/section/solar-icons/green.svg'

import Layout from '../UI/Layout/Layout'

const Solar = () => {
  const icons: Icons = [consultancyIcon, woodIcon, heatpumpIcon, solarIcon]
  const images: Images = [
    consultancy,
    wood,
    heatpump,
    {
      bg: solar,
      icons: [houseSolar, moduleSolar, batterySolar, greenSolar],
    },
  ]

  return (
    <Layout authLevelRequired={false}>
      <ServiceTemplate
        translationKey={'solar'}
        color={'green'}
        heroImage={hero}
        heroImageMobile={heroMobile}
        icon={icon}
        sectionIcons={icons}
        sectionImages={images}
      />
    </Layout>
  )
}

export default Solar

import props from '../../../../redux/props'
import { useSelector } from 'react-redux'
import LargeContainer from '../../Container/LargeContainer'
import logo from '../../../../assets/image/logo.svg'
import Call from '../../Call/Call'

const Footer = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="bg-pureBlack py-10">
      <LargeContainer>
        <div className="flex flex-col gap-10 lg:gap-0 lg:flex-row justify-between lg:items-center">
          <div className="flex flex-col gap-10 lg:gap-4 lg:w-1/3">
            <div className="flex flex-row justify-center lg:justify-start items-center gap-3">
              <img src={logo} alt="Mayr MTL GmbH" className="h-12 lg:h-9" />
              <div className="mt-1 font-bold text-lg text-white">{t.projectName}</div>
            </div>
            <div>
              <div className="text-white text-xs">{t.generic.company.name}</div>
              <div className="text-white text-xs">{t.generic.company.street}</div>
              <div className="text-white text-xs">{t.generic.company.city}</div>
              <div className="text-white text-xs">
                {t.generic.phonePrefix}
                {t.generic.company.phone}
              </div>
              <div className="text-white text-xs">{t.generic.company.mail}</div>
            </div>
          </div>
          <div className="flex flex-col gap-10 lg:w-1/3">
            <div className="flex flex-col lg:items-center gap-2">
              <div className="text-white text-xs whitespace-nowrap lg:text-center">{t.generic.openHours}</div>
              <Call />
            </div>
            <div className="flex flex-col lg:flex-row justify-between">
              {t.footer.links.map((link: { name: string; link: string }, i: number) => (
                <a href={link.link} className="text-sm text-gray" key={i}>
                  {link.name}
                </a>
              ))}
            </div>
          </div>
          <div className="lg:w-1/3 flex lg:justify-end">
            <div className="flex flex-col gap-4">
              <div className="text-white text-sm">&sup1;{` ${t.generic.company.name}`}</div>
              <div className="text-white text-sm font-bold">{t.footer.services.headline}</div>
              <div className="flex flex-col">
                {t.footer.services.items.map((item: { name: string; link: string }, i: number) => (
                  <a className="text-white text-xs" key={i} href={item.link}>
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
          {/* <div className={baseClasses}>
          <div id="trigger_user_centrics" className="cursor-pointer">
          {t.footer.cookieConsent.title}
          </div>
        </div> */}
        </div>
      </LargeContainer>
    </div>
  )
}

export default Footer

import { useSelector } from 'react-redux'
import Button from '../Button/Button'
import props from '../../../redux/props'
import Modal from '../Modal/Modal'
import { useState } from 'react'
import callIcon from '../../../assets/image/call/call.svg'

type Props = { dark?: boolean; inverted?: boolean }

const Call: React.FC<Props> = ({ dark, inverted }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(false)

  const toggle = () => setOpen(!open)

  return (
    <>
      <Button inverted={inverted} dark={dark} onClick={toggle} text={t.generic.cta.call} className="px-5 text-sm hidden lg:block" />
      <Button inverted={inverted} dark={dark} href={`tel:${t.generic.company.phone}`} text={t.generic.cta.call} className="px-5 text-sm lg:hidden" />
      <Modal show={open} onClose={toggle} title={t.call.title}>
        <div className="flex flex-row items-center gap-2 justify-center my-5">
          <img src={callIcon} alt="call" className="w-10 aspect-square" />
          <div className="text-gray text-xl font-bold">{t.generic.company.phone}</div>
        </div>
        <div className="text-center">
          {t.call.openHours.map((oH, i) => (
            <div key={i}>{oH}</div>
          ))}
        </div>
        <div className="text-center font-bold mt-5">{t.generic.company.name}</div>
        <div className="text-center">{t.generic.company.street}</div>
        <div className="text-center">{t.generic.company.city}</div>
      </Modal>
    </>
  )
}

export default Call

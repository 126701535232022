import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { useState } from 'react'
import Modal from '../../UI/Modal/Modal'
import Button from '../../UI/Button/Button'
import Input from '../../UI/Input/Input'
import DragAndDrop from '../../UI/DragAndDrop/DragAndDrop'
import useFileUpload, { UPLOAD_TYPES } from '../../hooks/useFileUpload'
import useSendForm, { Type } from '../../hooks/useSendForm'
import Progress from '../../UI/Progress/Progress'
import Loading from '../../UI/Loading/Loading'

type JobType = 'job' | 'apprenticeship'

export type Position = {
  name: string
  isFulltime: boolean
  type: JobType
  skillItems: string[]
  todoItems: string[]
}

export type PersonalData = {
  typeText: string
  position: string
  firstname?: string
  lastname?: string
  mail?: string
  phone?: string
  fileKey?: string
}

enum ProcessingState {
  DEFAULT,
  SUBMITTING,
  SUCCESS,
  ERROR,
}

const Item: React.FC<{ data: Position }> = ({ data }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)
  const [processingState, setProcessingState] = useState<ProcessingState>(ProcessingState.DEFAULT)
  const [personalData, setPersonalData] = useState<PersonalData>({
    typeText: `${t.career.positions.type[data.type]}${t.career.positions.typeSeparator}${
      data.isFulltime ? t.career.positions.fulltime : t.career.positions.parttime
    }`,
    position: data.name,
  })
  const [file, setFile] = useState<File | null>(null)
  const [progress, setProgress] = useState<number>(0)
  const fileUpload = useFileUpload()
  const sendForm = useSendForm()
  const [showFileError, setShowFileError] = useState<boolean>(false)

  const toggle = () => setOpen(!open)

  const set = (key: keyof PersonalData) => (value: string) => setPersonalData({ ...personalData, [key]: value })

  const submit = async () => {
    setShowFileError(false)
    if (file) {
      setProcessingState(ProcessingState.SUBMITTING)
      const response = await fileUpload(UPLOAD_TYPES.APPLICATION, file, (p) => setProgress(p))
      if (response) {
        const status = await sendForm(Type.APPLICATION, { ...personalData, fileKey: response?.key })
        if (status) {
          setProcessingState(ProcessingState.SUCCESS)
        } else {
          setProcessingState(ProcessingState.ERROR)
        }
      } else {
        setProcessingState(ProcessingState.ERROR)
      }
    } else {
      setShowFileError(true)
    }
  }

  return (
    <>
      <div className="flex flex-col lg:flex-row items-center gap-3">
        <div className="bg-lightGray w-full rounded-xl px-5 lg:px-10 py-3 flex flex-col lg:flex-row">
          <div className="font-bold text-gray lg:w-5/12">
            {data.name}
            {t.career.positions.nameSuffix}
          </div>
          <div className="font-thin text-gray lg:w-2/12 lg:text-center">
            {data.isFulltime ? t.career.positions.fulltime : t.career.positions.parttime}
          </div>
          <div className="font-thin text-gray lg:w-2/12 lg:text-center">{t.career.positions.type[data.type]}</div>
          <div onClick={toggle} className="font-bold text-gray lg:w-3/12 text-sm underline cursor-pointer lg:text-right">
            {t.career.positions.detailCTA}
          </div>
        </div>
        <Button text={t.career.positions.applyCTA} dark onClick={toggle} className="h-full lg:py-3 whitespace-nowrap" />
      </div>
      <Modal
        show={open}
        large
        onClose={toggle}
        title={data.name + t.career.positions.nameSuffix}
        onConfirm={processingState === ProcessingState.DEFAULT ? submit : null}
        confirmButtonText={t.career.positions.submitApplicationCTA}
      >
        <div className="mt-3 text-left">
          {t.career.positions.typePrefix}
          {t.career.positions.type[data.type]}
          {t.career.positions.typeSeparator}
          {data.isFulltime ? t.career.positions.fulltime : t.career.positions.parttime}
        </div>
        <div className="flex flex-col lg:flex-row gap-5 mt-3">
          <div className="flex-1 flex flex-col">
            <div className="font-bold">{t.career.positions.skillItemsTitle[data.type]}</div>
            {data.skillItems.map((item, i) => (
              <div key={i} className="flex items-center gap-3">
                <div className="w-2 shrink-0 grow-0 aspect-square bg-black rounded-full" />
                <div className="text-left">{item}</div>
              </div>
            ))}
          </div>
          <div className="flex-1 flex flex-col">
            <div className="font-bold">{t.career.positions.todoItemsTitle[data.type]}</div>
            {data.todoItems.map((item, i) => (
              <div key={i} className="flex items-center gap-3">
                <div className="w-2 shrink-0 grow-0 aspect-square bg-black rounded-full" />
                <div className="text-left">{item}</div>
              </div>
            ))}
          </div>
        </div>
        {processingState === ProcessingState.DEFAULT && (
          <div className="flex flex-col gap-3 mt-5">
            <div className="flex flex-col lg:flex-row gap-3">
              <Input className="flex-1" value={personalData.firstname} onChange={set('firstname')} placeholder={t.generic.firstname} required />
              <Input className="flex-1" value={personalData.lastname} onChange={set('lastname')} placeholder={t.generic.lastname} required />
            </div>
            <div className="flex flex-col lg:flex-row gap-3">
              <Input className="flex-1" value={personalData.mail} onChange={set('mail')} placeholder={t.generic.mail} type="mail" required />
              <Input className="flex-1" value={personalData.phone} onChange={set('phone')} placeholder={t.generic.phone} required />
            </div>
            <div>
              <DragAndDrop className="h-full w-full overflow-hidden" onFile={setFile}>
                <div className="flex flex-row items-center  h-full">
                  <div className="border-nonefont-bold w-full py-3 px-10 bg-lightGray text-gray rounded-lg">
                    <div className="flex flex-col">
                      <div className="text-gray font-bold">{t.UI.dnd.text}</div>
                      <div className="text-gray">{t.UI.dnd.hint}</div>
                    </div>
                  </div>
                </div>
                {!!file && (
                  <div className="mt-2 truncate w-full text-xs">
                    {t.UI.dnd.selectedPrefix}
                    {file.name}
                  </div>
                )}
              </DragAndDrop>
            </div>
            {showFileError && <div className="text-orange text-center mt-5">{t.career.positions.apply.fileError}</div>}
          </div>
        )}
        {processingState === ProcessingState.SUBMITTING && (
          <div className="flex flex-col justify-center mt-5 gap-3">
            <div className="text-center font-bold text-gray">{t.career.positions.apply.processing}</div>
            {!progress || progress === 0 || progress === 100 ? <Loading loading={false} /> : null}
            <Progress progress={progress} />
          </div>
        )}
        {processingState === ProcessingState.SUCCESS && (
          <div className="flex flex-col justify-center mt-5 gap-3">
            <div className="text-center font-bold text-green">{t.career.positions.apply.success}</div>
          </div>
        )}
        {processingState === ProcessingState.ERROR && (
          <div className="flex flex-col justify-center mt-5 gap-3">
            <div className="text-center font-bold text-orange">{t.career.positions.apply.error}</div>
          </div>
        )}
      </Modal>
    </>
  )
}

export default Item

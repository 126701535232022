import ServiceTemplate, { Icons, Images } from './ServiceTemplate/ServiceTemplate'
import icon from '../../assets/image/services/air/icon.svg'
import hero from '../../assets/image/services/air/hero.jpg'
import heroMobile from '../../assets/image/services/air/hero_mobile.jpg'

import consultancyIcon from '../../assets/image/services/air/icon/consultancy.svg'
import planningIcon from '../../assets/image/services/air/icon/planning.svg'
import upgradeIcon from '../../assets/image/services/air/icon/upgrade.svg'
import installationIcon from '../../assets/image/services/air/icon/installation.svg'
import maintencanceIcon from '../../assets/image/services/air/icon/maintencance.svg'
import technologieIcon from '../../assets/image/services/air/icon/technologie.svg'

import consultancy from '../../assets/image/services/air/section/consultancy.jpg'
import planning from '../../assets/image/services/air/section/planning.jpg'
import upgrade from '../../assets/image/services/air/section/upgrade.jpg'
import installation from '../../assets/image/services/air/section/installation.jpg'
import maintenance from '../../assets/image/services/air/section/maintenance.jpg'
import technology from '../../assets/image/services/air/section/technology.jpg'

import nightTechnologie from '../../assets/image/services/air/section/technology-icons/night.svg'
import windTechnologie from '../../assets/image/services/air/section/technology-icons/wind.svg'
import temperaturTechnologie from '../../assets/image/services/air/section/technology-icons/temperatur.svg'
import blockTechnologie from '../../assets/image/services/air/section/technology-icons/block.svg'

import Layout from '../UI/Layout/Layout'

const Air = () => {
  const icons: Icons = [consultancyIcon, planningIcon, upgradeIcon, installationIcon, maintencanceIcon, technologieIcon]
  const images: Images = [
    consultancy,
    planning,
    upgrade,
    installation,
    maintenance,
    {
      bg: technology,
      icons: [nightTechnologie, windTechnologie, temperaturTechnologie, blockTechnologie],
    },
  ]

  return (
    <Layout authLevelRequired={false}>
      <ServiceTemplate
        translationKey={'air'}
        color={'lightBlue'}
        heroImage={hero}
        heroImageMobile={heroMobile}
        icon={icon}
        sectionIcons={icons}
        sectionImages={images}
      />
    </Layout>
  )
}

export default Air

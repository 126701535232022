import styled from 'styled-components'
import goDown from '../../../../assets/image/go-down.svg'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import Button from '../../../UI/Button/Button'
import Call from '../../../UI/Call/Call'

const Content = styled.div<{ bg: string; bgMobile: string }>`
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  background-position: bottom center;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    background-image: url(${({ bgMobile }) => bgMobile});
    background-position: center 25%;
  }
`

type Props = {
  translationKey: string
  bg: string
  bgMobile: string
  icon: string
}

const Hero: React.FC<Props> = ({ translationKey, bg, bgMobile, icon }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <Content className="min-h-screen pt-24 pb-20 md:py-0 md:h-screen relative flex items-center" bg={bg} bgMobile={bgMobile}>
      <div className="w-full px-3 lg:ml-auto lg:w-2/3 xl:w-[850px] lg:px-0 h-full flex items-center">
        <div className="flex gap-12 flex-col lg:w-11/12">
          <div>
            <div className="flex flex-row items-center gap-3">
              <img src={icon} alt="" className="h-16 lg:h-20" />
              <div>
                {t[translationKey].hero.iconName.map((t: string, i: number) => (
                  <div className="font-light text-white leading-5" key={i}>
                    {t}
                  </div>
                ))}
              </div>
            </div>
            <div className="font-bold text-2xl lg:text-3xl leading-9 text-white mt-5">{t[translationKey].hero.headline}</div>
            <div className="text-xl lg:text-2xl leading-9 mt-2 text-white">{t[translationKey].hero.subline}</div>
          </div>
          <div className="flex gap-3 flex-col">
            {t[translationKey].hero.text.map((text: string, index: number) => (
              <div key={index} className="text-white text-sm">
                {text}
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-3 items-center md:flex-row md:gap-5">
            <Button href="/kontakt" text={t.generic.cta.offer} />
            <Call inverted />
          </div>
        </div>
      </div>
      <img src={goDown} alt="" className="hidden md:block left-1/2 -translate-x-1/2 absolute bottom-10 z-1 h-16" />
    </Content>
  )
}

export default Hero

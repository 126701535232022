import { useNavigate } from 'react-router-dom'
import Navigation from './Navigation/Navigation'
import logo from '../../../../assets/image/logo.svg'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { useEffect, useState } from 'react'

const Header = () => {
  const navigate = useNavigate()
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const isLoggedIn = () => !!accountLink
  const t = useSelector((s) => s[props.TRANSLATION])

  const TOP_OFFSET = window.innerHeight
  const [didScroll, setDidScroll] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= TOP_OFFSET) {
        setDidScroll(true)
      } else {
        setDidScroll(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [TOP_OFFSET])

  const goHome = () => {
    let path = '/'
    if (isLoggedIn()) {
      path = '/admin/moderate'
    }
    navigate(path)
  }

  return (
    <div className="px-3 z-20 top-3 fixed w-full">
      <div className={`shadow-sm transition-all items-center relative flex-row justify-between w-full flex rounded-xl px-3 py-1`}>
        <div
          className={`absolute top-0 left-0 w-full h-full -z-10 rounded-xl backdrop-blur-sm ${
            didScroll ? 'bg-black bg-opacity-40' : 'bg-white bg-opacity-20'
          }`}
        ></div>
        <div onClick={goHome} className="flex flex-row gap-3 items-center self-start cursor-pointer">
          <img src={logo} alt="Mayr MTL GmbH" className="h-9" />
          <div className="font-bold text-lg text-white whitespace-nowrap">{t.projectName}</div>
        </div>
        <Navigation didScroll={didScroll} />
      </div>
    </div>
  )
}

export default Header

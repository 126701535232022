import MediumContainer from '../UI/Container/MediumContainer'
import Layout from '../UI/Layout/Layout'
import './legal.css'

const Imprint = () => {
  return (
    <Layout authLevelRequired={false}>
      <div className="bg-black text-white pt-36 pb-20 text-center text-2xl">Impressum</div>
      <div className="py-20">
        <MediumContainer>
          <div className="legal-text">
            <p>
              Mayr MTL GmbH
              <br />
              Marktanger 7<br />
              86447 Aindling
            </p>

            <p>
              Handelsregister: HRB 21349
              <br />
              Registergericht: Augsburg
            </p>

            <p>
              <strong>Vertreten durch:</strong>
              <br />
              Nathalie Carl
            </p>

            <h2>Kontakt</h2>
            <p>
              Telefon: 08237 891
              <br />
              Telefax: 08237 1040
              <br />
              E-Mail: info@heizung-mayr.com
            </p>

            <h2>Umsatzsteuer-ID</h2>
            <p>
              Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:
              <br />
              DE 244 391 945
            </p>

            <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
            <p>
              <a href="/datenschutz" className="underline">
                Datenschutz
              </a>
            </p>
            <p>
              <a
                href="https://s3.eu-central-1.amazonaws.com/think-internet-de.heizung-mayr.storage/Dokument-Zur-Datenverarbeitung.pdf"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                Grundsätze der Datenverarbeitung bei der Mayr MTL GmbH
              </a>
            </p>
          </div>
        </MediumContainer>
      </div>
    </Layout>
  )
}

export default Imprint

import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import bg from '../../../assets/image/career/positions/bg.jpg'
import bgMobile from '../../../assets/image/career/positions/bg_mobile.jpg'
import { getUUID } from '../../../utility'
import Item, { Position } from './Item'
import Container from '../../UI/Container/Container'
import styled from 'styled-components'

const Positions = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const Content = styled.div`
    background-image: url(${bg});
    background-size: cover;
    background-position: center top;
    @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      background-image: url(${bgMobile});
      background-position: center top;
      background-size: 100% auto;
      background-repeat: no-repeat;
    }
  `

  return (
    <Content className="mt-28 scroll-mt-16 flex items-center justify-center" id="formular">
      <Container>
        <div className="p-5 lg:p-10 pt-5 mb-20 mt-[30vh] bg-white">
          <div className="text-lg lg:text-xl text-center text-gray font-bold">{t.career.positions.headline}</div>
          <div className="flex flex-col gap-5 lg:gap-3 mt-5 mx-auto lg:w-full">
            {t.career.positions.items.map((position: Position) => (
              <Item data={position} key={getUUID()} />
            ))}
          </div>
        </div>
      </Container>
    </Content>
  )
}
export default Positions

type Props = {
  children: React.ReactNode
  className?: string
}

const Container: React.FC<Props> = ({ children, className = '' }) => {
  return (
    <div className={`w-full max-w-screen-2xl mx-auto ${className}`}>
      <div className="px-3 sm:px-6">{children}</div>
    </div>
  )
}

export default Container

import { useRef, useState } from 'react'
import { useEffect } from 'react'
import style from './style'
import pin from '../../../../assets/image/contact/pin.png'
import mapFallback from '../../../../assets/image/contact/map-fallback.png'
import styled from 'styled-components'

const Map = styled.div`
  .uc-embedding-container {
    display: none;
  }
`

const GoogleMap = ({ options, markers }) => {
  const ref = useRef()
  const [isAvailable, setIsAvailable] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      // @ts-ignore
      if (!!window.google && !isAvailable) {
        setIsAvailable(true)
        // @ts-ignore
        const map = new window.google.maps.Map(ref.current, {
          styles: style,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          ...options,
        })
        markers.forEach((marker) => {
          // @ts-ignore
          new window.google.maps.Marker({
            position: marker.coordinates,
            map,
            icon: {
              url: pin,
              // @ts-ignore
              scaledSize: new window.google.maps.Size(51.8, 70),
            },
          })
        })
      }
    }, 1000)
    return () => clearTimeout(timeout)
  })

  return (
    <Map ref={ref} id="map" className="w-full h-full outline-none border-none relative">
      <img className="w-full h-full object-cover absolute top-0 left-0 z-10" src={mapFallback} alt="Fallback" />
    </Map>
  )
}

export default GoogleMap

type Props = Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
  onChange?: (value: string) => void
  value: string
  placeholder?: string
  disabled?: boolean
  className?: string
  rows?: number
  required?: boolean
  id?: string
  minCharacters?: number
  maxCharacters?: number
  useRef?: any
}

const Textarea: React.FC<Props> = ({
  onChange,
  value,
  placeholder,
  disabled,
  className,
  rows = 3,
  required = false,
  id,
  minCharacters,
  maxCharacters,
  useRef,
}) => {
  return (
    <textarea
      id={id}
      ref={useRef}
      disabled={disabled}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      placeholder={placeholder}
      required={required}
      className={`border-none placeholder-gray font-bold w-full py-3 px-10 bg-lightGray text-gray rounded-lg focus:outline-none focus:shadow-outline ${className}`}
      rows={rows}
      minLength={minCharacters}
      maxLength={maxCharacters}
    />
  )
}

export default Textarea

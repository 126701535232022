import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { NavigationItem } from '../../../types'
import oilIcon from '../../../../../../../assets/image/services/oil/icon.svg'
import airIcon from '../../../../../../../assets/image/services/air/icon.svg'
import waterIcon from '../../../../../../../assets/image/services/water/icon.svg'
import solarIcon from '../../../../../../../assets/image/services/solar/icon.svg'

type Props = {
  data: NavigationItem
  className?: string
  onClick?: () => void
  didScroll?: boolean
  mobile?: boolean
}

const Default: React.FC<Props> = ({ data, className = '', onClick = null, didScroll = false, mobile = false }) => {
  const icons = {
    oil: oilIcon,
    air: airIcon,
    water: waterIcon,
    solar: solarIcon,
  }

  const clicked = () => {
    if (onClick) {
      onClick()
    }
  }

  if (!!data.items) {
    return (
      <div className={`group ${className}`}>
        <div
          className={`flex ${
            mobile ? 'flex-col' : 'flex-row items-center group-hover:bg-white group-hover:bg-opacity-20'
          }  py-1 lg:px-3 rounded-full gap-1 relative lg:cursor-pointer`}
        >
          <div className={`lg:text-white no-underline`}>{data.name}</div>
          {!mobile && <ChevronDownIcon className={`w-4 fill-white transition-all lg:group-hover:rotate-180`} />}
          <div className={`${mobile ? 'block' : 'absolute top-full hidden group-hover:flex left-0'}`}>
            <div className={`${!mobile && 'bg-white rounded-xl lg:shadow-sm py-5 px-7'} lg:mt-3 transition-all`}>
              <div className="hidden lg:block font-bold text-gray">{data.itemsTitle}</div>
              <div className="flex w-96 flex-row flex-wrap justify-between gap-y-2 mt-3">
                {data.items.map((item: NavigationItem) => (
                  <a href={item.link} className="w-full lg:w-44 no-underline flex flex-row items-center gap-2" key={item.name}>
                    <img src={icons[item.iconID]} alt={item.name} className="w-11 aspect-square" />
                    <div className="text-xs">{item.name}</div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={`flex flex-row gap-1 items-center`} onClick={clicked}>
      <a href={data.link || ''} className={`lg:text-white no-underline hover:bg-white hover:bg-opacity-20 py-1 lg:px-3 rounded-full ${className}`}>
        {data.name}
      </a>
    </div>
  )
}

export default Default
